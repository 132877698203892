<template>
    <div class="container">
        <h1 class="mt-4">Schülerberichte</h1>
        <hr>
        <p class="mb-4">
            Hier kannst du einige Berichte von Schülern
            über den Schulalltag und die Herausforderungen,
            die man zu bewältigen hat, lesen.
        </p>
        <div
            class="accordion" 
            role="tablist"
        >
            <b-card 
                no-body
                v-for="(bericht, index) in berichteData"
                :key="index"        
            >
                <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                >
                    <b-button
                        class="btn-bsz"
                        @click="bericht.visible = !bericht.visible"
                        v-html="bericht.title"
                        variant="info"
                        block
                    />
                </b-card-header>
                <b-collapse
                    v-model="bericht.visible"
                    accordion="qna-accordion"
                    role="tabpanel"
                >
                    <b-card-body>
                        <b-card-text v-html="bericht.content"/>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BButton, BCollapse, BCardText } from "bootstrap-vue"
import berichteData from '@/assets/json/berichte.json';
export default {
    name: "Berichte",
    components: { BCard, BCardHeader, BCardBody, BButton, BCollapse, BCardText },
    data: () => ({
        berichteData
    })
}
</script>